.Testimonials {
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}

.left-t {
    flex: 1 1;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}

.left-t>:nth-child(1) {
    color: var(--orange);
    font-weight: bold;
}

.left-t>:nth-child(2),
.left-t>:nth-child(3) {
    font-weight: bold;
    font-size: 3rem;
}

.left-t>:nth-child(4) {
    text-transform: none;
    text-align: justify;
    line-height: 40px;
    letter-spacing: 1px;
}

.rigth-t {
    flex: 1;
    position: relative;
}

.rigth-t>img {
    position: absolute;
    top: 2rem;
    right: 5rem;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
}

.rigth-t>:nth-child(1) {
    width: 17rem;
    height: 20rem;
    position: absolute;
    right: 6rem;
    top: 0.9rem;
    border: 2px solid orange;
    background-color: transparent;
}

.rigth-t>:nth-child(2) {
    width: 17rem;
    height: 20rem;
    position: absolute;
    right: 4rem;
    top: 4rem;
    background: var(--planCard);
}

.arrow {
    position: absolute;
    display: flex;
    bottom: 1rem;
    left: 3rem;
    gap: 1rem;
}

.arrow>img {
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width :768px) {
    .Testimonials {
        margin-top: -3rem;
        flex-direction: column;
    }

    .left-t>:nth-child(2),
    .left-t>:nth-child(3) {
        font-size: xx-large;
    }

    .rigth-t {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
    }

    .rigth-t>:nth-child(1) {
        position: absolute;
        top: 0rem;
        right: 0rem;

    }

    .rigth-t>:nth-child(2) {
        position: absolute;
        top: 2rem;
        right: -1.5rem;
    }

    .rigth-t>img {
        position: relative;
        top: 0.7rem;
        left: 0rem;
        align-self: center;
    }

    .arrow {
        bottom: -5rem;
        left: auto;
    }
}